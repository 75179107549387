$header-color: #B6D7FC;
$header-text-color: black;

$aside1-color: #2769B0;
$aside1-text-color: white;

$hr-color: white;

$aside2-color: #E9B76F;
$aside2-text-color: black;

$main-color: #55A1F5;

$footer-color: #043565;
$footer-text-color: white;

$shadow-color: #333333;

$button-color: green;
$button-text-color: white;

.wrapper {
  display: flex;  
  flex-flow: row wrap;
  font-weight: normal;
  text-align: center;
}

.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}

.hero {
  /*font-size: 300%;*/
  font-size: 10vw;
  margin-bottom: 0.3ex;
  margin-top: 0.3ex;  
}

.shadow {
  box-shadow: 2px 2px 4px $shadow-color;
}

hr {
  margin-top: 3ex;
  margin-bottom: 2ex;
  color: $hr-color;
}

.left {
  float: left;
}

.right {
  float: right;
}

.runright {
  box-shadow: 4px 4px 8px $shadow-color;
  float: right;
  margin: 2ex;
}

.button {
  background-color: $button-color;
  border: none;
  color: $button-text-color;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2ex;
  border-radius: 0.5em;
  margin-bottom: 2ex;
}

.button:hover {
  /*box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);*/
  box-shadow: 3px 4px 4px rgba(0,0,0,0.24), 3px 3px 4px rgba(0,0,0,0.19);

}

.header {
  background: $header-color;
  color: $header-text-color;
}

.footer {
  background: $footer-color;
  color: $footer-text-color;
  font-style: italic;
  font-size: xx-small;
  margin-bottom: 2ex;
}

.main {
  text-align: left;
  background: $main-color;
}

.aside-1 {
  background: $aside1-color;
  color: $aside1-text-color;
}

.aside-2 {
  /*background: gold;*/
  background: $aside2-color;
  color: $aside2-text-color;
}

@media all and (min-width: 600px) {
  .aside { flex: 1 0 0; }
}

@media all and (min-width: 800px) {
  .main    { flex: 3 0px; }
  .aside-1 { order: 1; } 
  .main    { order: 2; }
  .aside-2 { order: 3; }
  .footer  { order: 4; }
}

body {
  padding: 2em; 
  background-color: $main-color;
  background-image: url("/img/sand.jpg");
  background-repeat: no-repeat;
  background-position: left top;
  background-attachment: fixed;
  background-size: cover;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

/* unvisited link */
a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: yellow;
  text-decoration: none;
}

h1, h2, h3 {
  font-family: 'Amatic SC', cursive;
}

.carousel {
  position:relative;
  width:75%;
  border:10px solid white;
  margin:20px auto;
  box-shadow:0px 0px 40px #000;
}
.carousel > img {
  display:none;
  width:100%;
}
.carousel > input {
  position:absolute;
  left:-9999px;
}
.carousel > input:checked + label + img {
  display:block;
}

.carousel > label,
.carousel > input:checked ~ label ~ label ~ label ~ label {
  display:none;
}
.carousel > input:checked + label {
  display:block;
  left:0;
}
.carousel > input:checked ~ label ~ label ~ label {
  display:block;
  right:0;
}

.carousel > label {
  position:absolute;
  top:0;
  width:25%;
  height:100%;
  visibility:hidden; /* was hidden */
}
.carousel > label:before {
  content:'';
  display:block;
  position:absolute;
  width:100%;
  height:100%;
  visibility:visible;
}
.carousel > label:after {
  display:block;
  position:absolute;
  top:50%;
  width:25px;
  height:25px;
  line-height:22px;
  margin:-15px 10px 0 10px;
  background-color:black;
  color:white;
  font-family:'Arial';
  font-weight:bold;
  font-size:18px;
  text-align:center;
  visibility:visible; /* was hidden */
  border:2px solid white;
  border-radius:20px;
  box-shadow:0 3px 4px black;
}
.carousel > label:hover:after {
  visibility:visible;
}

.carousel > input:checked + label:after {
  left:0;
  content:'\00AB';
}
.carousel > input:checked ~ label ~ label ~ label:after {
  right:0;
  content:'\00BB';
}
